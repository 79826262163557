// LoadTable.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Container, Typography, IconButton, Chip, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalButton from './modalButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EventIcon from '@mui/icons-material/Event';

const baseUrl = process.env.REACT_APP_API_URL
const loadsUrl = baseUrl + '/loads'
const usernameUrl = baseUrl + '/username'
const locationNameUrl = baseUrl + '/location-name'
const eventsUrl = baseUrl + '/events'

const LoadTable = () => {
    const [loads, setLoads] = useState([]);
    const [loadsNames, setLoadsNames] = useState([])
    const [reload, setReload] = useState([])
    const [params, setParams] = useState([])
    const [selected, setSelected] = useState()
    const [events, setEvents] = useState([])

    useEffect(() => {
        const endpoint = buildLoadsReq(loadsUrl)
        const fetchLoads = async () => {
            try {
                const response = await axios.get(endpoint);
                setLoads(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchLoads();
    }, [reload, params]);

    useEffect(() => {
        const addNamesToLoads = async () => {
            try {
                const updatedLoadsPromises = loads.map(async (load) => {
                    const responseLoc = await axios.get(`${locationNameUrl}/${load.location_id}`);
                    const responseUser = await axios.get(`${usernameUrl}/${load.creator_id}`)
                    return { ...load, locationName: responseLoc.data.name, username: responseUser.data.name };
                });

                const updatedLoads = await Promise.all(updatedLoadsPromises);
                setLoadsNames(updatedLoads);
            } catch (error) {
                console.error("Error fetching names: ", error);
            }
        };
        console.log(`api url: ${baseUrl}`)
        addNamesToLoads();
    }, [loads])

    const getEvents = async (id) => {
        const fullUrl = eventsUrl + '/' + id
        try {
            const response = await axios.get(fullUrl)
            setEvents(response.data)
            console.log(`set events ${JSON.stringify(response)}`)
        }
        catch (error){
            console.log("Error fetching history")
        }
    }

    const refresh = () => {
        setLoads([])
        setReload(!reload)
        setSelected()
    }

    const buildLoadsReq = (request) => {
        let builtRequest = request
        if (params.length > 0){
            builtRequest = builtRequest.concat('?')
            params.map((param, index) => {
                builtRequest = builtRequest.concat(param)
                if (index < params.length - 1){
                    builtRequest += '&'
                }
                return null
            })
        }
        return builtRequest
    }

    const handleParamDelete = (key) => {
        console.log(`deleting param, e.t.v = ${key}`)
        setParams(params.filter((_,i)=>(i!==key)))
        console.log(`params: ${params}`)
    }

    const handleSelect = (index) => {
        setSelected(index)
        getEvents(loads[index].id)
    }

    const handleDeselect = () => {
        setSelected()
        setEvents([])
    }

    const formatDateTime = (datetime) => {
        const date = new Date(datetime)
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short', timeZone: 'Pacific/Guam' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    return (
        <Container>
            <Box sx={{ my: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" component="h2">
                    Active Loads
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {params.map((param, index) => (
                        <Chip label={param} key={index} onDelete={() => handleParamDelete(index)}></Chip>
                    ))}
                    <ModalButton params={params} setParams={setParams}/>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{ overflow: 'hidden', borderRadius: 0 }}>
                <Table sx={{ minWidth: 650 }} aria-label="loads table" size="small" >
                    <TableHead sx={{ backgroundColor: 'primary.main', '.MuiTableCell-head': { fontWeight: 'bold', color: 'white' } }}>
                        <TableRow>
                            <TableCell>
                                <IconButton onClick={refresh}>
                                    <CachedIcon/>
                                </IconButton>
                            </TableCell>
                            <TableCell> Load Description </TableCell>
                            <TableCell align='right'>Load ID</TableCell>
                            <TableCell align="right">Location</TableCell>
                            <TableCell align='right'>User</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadsNames.map((row, index) => [
                            <TableRow
                                key={row.description}
                                sx={{
                                    '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                                    '&:hover': { backgroundColor: 'action.selected' },
                                }}
                            >
                                <TableCell>
                                    {selected === index ? <KeyboardArrowDownIcon fontSize='small' onClick={() => (handleDeselect())}/> : <KeyboardArrowRightIcon fontSize='small' onClick={() => (handleSelect(index))}/>}
                                </TableCell>
                                <TableCell component="th" scope="row"> 
                                    {row.description}
                                </TableCell>
                                <TableCell align="right">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">
                                    {row.locationName}
                                </TableCell>
                                <TableCell align="right">
                                    {row.username}
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton size="small" sx={{padding:0}}>
                                        <DeleteForeverIcon size="small" sx={{padding:0}}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>,
                                selected === index && (
                                    <TableRow key={index}>
                                        <TableCell colSpan={6}>
                                            <Paper elevation={1} sx={{maxHeight: 200, overflow: 'auto', p: 2}}>
                                                <List>
                                                    {events.map((event, index) => (
                                                        <ListItem key={index} alignItems="flex-start">
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <EventIcon /> {/* Change icons based on the event type or action */}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={`Action: ${event.action}`}
                                                                secondary={
                                                                    <>
                                                                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                                                            Location ID: {event.location_id}
                                                                        </Typography>
                                                                        — {formatDateTime(event.datetime)} {/* Example of how to format date and time */}
                                                                    </>
                                                                }
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Paper>
        </TableCell>
                                    </TableRow>
                                )
                                ])}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default LoadTable;
