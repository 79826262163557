import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL

const ModalButton = ({ params, setParams })=> {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState('');
  const [secondSelection, setSecondSelection] = useState('');
  const [filters, setFilters] = useState([])
  const [locations, setLocations] = useState([])
  const [users, setUsers] = useState([])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
    setSecondSelection('');
  };
  const handleSecondSelectionChange = (event) => {
    setSecondSelection(event.target.value);
  };

  useEffect(()=>{
    setFilters(['Location', 'Users'])

    const fetchLocations = async () => {
      try {
          const locResponse = await axios.get(baseUrl + '/locations');
          setLocations(locResponse.data);
          const userResponse = await axios.get(baseUrl + '/users')
          setUsers(userResponse.data)
      } catch (error) {
          console.error('Error fetching data: ', error);
      }
    };
    fetchLocations();
  }, [])

  const handleAddFilter = () => {
    const filterBy = filters[selection].toLowerCase()
    let filterValue
    if (filterBy === 'location'){
      filterValue = locations[secondSelection].id
    }
    else if (filterBy === 'user'){
      filterValue = users[secondSelection].id
    }
    setParams([...params, `${filterBy}=${filterValue}`])
    console.log([...params, `${filterBy}=${filterValue}`])
    setSelection('')
    setSecondSelection('')
    handleClose()
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>Add Filter</Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Select Filters
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selection}
              label="First Choice"
              onChange={handleSelectionChange}
            >
              {filters.map((filter, index) => (
                <MenuItem value={index}>{filter}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selection !== undefined && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Select
                id="second-select"
                value={secondSelection}
                label="Second Choice"
                onChange={handleSecondSelectionChange}
              >
                {selection === 0 ? locations.map((location, index) => (
                  <MenuItem value={index}>{location.name}</MenuItem>
                )) : users.map((user, index) => (
                  <MenuItem value={index}>{user.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleClose}>Close</Button>
            <Button variant="contained" onClick={handleAddFilter}>Add Filter</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalButton